<template>
  <validation-observer ref="observer">
    <v-form ref="form" @submit.prevent="onSubmit" :disabled="loading">
      <!-- @slot default -->
      <slot />

      <slot name="actions">
        <div class="mt-3 d-flex justify-center">
          <v-btn
            v-if="!hideSave"
            color="primary"
            type="submit"
            :class="hideCancel ? '' : 'mr-5'"
            :disabled="loading"
          >
            {{ $t('btn.submit') }}
          </v-btn>
          <v-btn
            v-if="!hideCancel"
            color="error"
            class="ml-5"
            :disabled="loading"
            @click="onCancel"
          >
            {{ $t('btn.cancel') }}
          </v-btn>
        </div>
      </slot>
    </v-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'app-form',
  components: {
    ValidationObserver
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    hideSave: {
      type: Boolean,
      default: false
    },
    hideCancel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onSubmit() {
      /**
       * Passthrough on submit form
       * @type {Event}
       */
      ;(await this.$refs.observer.validate()) && this.$emit('submit')
    },
    onCancel() {
      /**
       * Passthrough on cancel
       * @type {Event}
       */
      this.$emit('cancel')
    }
  }
}
</script>
