<template>
  <div class="forget-password">
    <h2 class="auth-layout--title">{{ $t('menu.forgetPassword') }}</h2>
    <app-form hide-cancel @submit="onSubmit" :loading="loading">
      <app-input
        name="email"
        rules="required|email"
        :label="$t('fields.email')"
        :binds="{ singleLine: true, prependIcon: 'mdi-email' }"
        v-model="formData.email"
      />
      <template v-slot:actions>
        <v-btn
          color="primary"
          type="submit"
          width="100%"
          class="mt-2"
          :disabled="loading"
        >
          {{ $t('btn.submit') }}
        </v-btn>
      </template>
    </app-form>
    <div class="auth-layout--footer">
      <router-link :to="{ name: 'login' }">
        {{ $t('label.backToLogin') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AppForm from '@components/AppForm'
import AppInput from '@components/AppInput'

export default {
  name: 'forget-password',
  components: {
    AppForm,
    AppInput
  },
  data() {
    return {
      formData: {},
      loading: false
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.$store
        .dispatch('forgetPassword', this.$api, this.formData)
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'login' })
        })
    }
  }
}
</script>
